//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      list: [],
      categorys: [
        {
          img: require('../images/resgiter.png'),
          label: '收文登記',
          auth_list: [...this.supers, ...this.commissioners],
          route: {
            name: 'mobileBillRegister',
          },
        },
        {
          img: require('../images/approval.png'),
          label: '公文審批',
          auth_list: [...this.supers, ...this.commons],
          route: {
            name: 'mobileDocumentApproval',
          },
        },
        {
          img: require('../images/send.png'),
          label: '公文發送',
          auth_list: [...this.supers, ...this.commissioners],
          route: {
            name: 'mobileBillSend',
          },
        },
        {
          img: require('../images/tag.png'),
          label: '標簽管理',
          auth_list: [...this.supers],
          route: {
            name: 'mobileLabelManage',
          },
        },
      ],
    }
  },
  props: {
    supers: {
      type: Array,
      default: [],
    },
    commissioners: {
      type: Array,
      default: [],
    },
    commons: {
      type: Array,
      default: [],
    },
    auth_code: {
      type: String,
      default: '',
    },
  },
  computed: {},
  methods: {
    handleClick({ route }) {
      this.$router.push(route)
    },
  },
}
